<template>
  <div class="step-container">
    <el-tag :color="stepData.preActionResult === 'fail' ? 'red' : '#bfa'">
      {{ stepData.name }}
    </el-tag>
    <span
      v-if="
        stepData.nextActionTemplates && stepData.nextActionTemplates.length > 0
      "
      style="
        display: inline-block;
        width: 30px;
        height: 1px;
        background-color: #000;
        margin: 0 5px 5px 5px;
      "
    ></span>
    <div class="arr">
      <Step
        v-for="(item, index) in stepData.nextActionTemplates"
        :key="index"
        :stepData="item"
      >
      </Step>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',

  props: ['stepData'],
  data() {
    return {}
  },
  mounted() {},
  methods: {
    print(test) {
      console.log(test, 'test')
    },
  },
}
</script>
<style lang="scss" scoped>
.step-container {
  width: max-content;
  display: flex;
  align-items: center;
}
.first {
  margin-bottom: 10px;
}
</style>
