<template>
  <div class="outContainer">
    <div class="search">
      <el-select
        class="each"
        v-model="searchInfo.param"
        clearable
        placeholder="系统类型"
      >
        <el-option
          v-for="item in systemTypeEnumOptions"
          :key="item.code"
          :label="item.desc"
          :value="item.code"
        >
        </el-option>
      </el-select>
      <el-button type="primary" size="mini" @click="handleSearch('search')"
        >搜索</el-button
      >
      <el-button type="primary" size="mini" @click="handleSearch('clear')"
        >清空搜索条件</el-button
      >
      <el-button size="mini" @click="handleAdd(null)">新增</el-button>
    </div>
    <div class="tableContainer">
      <el-table :data="tableData" height="100%" style="width: 100%">
        <el-table-column prop="name" align="center" label="模板名称">
        </el-table-column>
        <el-table-column prop="systemType" align="center" label="所属系统">
        </el-table-column>
        <el-table-column prop="timeConsuming" align="center" label="耗时(秒)">
        </el-table-column>
        <el-table-column prop="remark" align="center" label="描述">
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <!-- <el-button size="mini" @click="checkNode2(scope.row)"
              >查看流程图</el-button
            > -->
            <el-button size="mini" @click="changeNode(scope.row)"
              >查看流程图</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 流水模板 -->
    <el-dialog
      title="流水节点"
      :visible.sync="showNode"
      append-to-body
      width="80%"
    >
      <div class="container">
        <!-- <el-timeline style="height: 100%; overflow-y: auto">
          <el-timeline-item
            v-for="(item, index) in nodeList"
            :key="index"
            :timestamp="item.name"
            placement="top"
            type="primary"
          >
            <div
              style="width: 100%; height: 300px; overflow-y: auto"
              :id="'actionChart' + index"
            ></div>
          </el-timeline-item>
        </el-timeline> -->
        <!-- 去掉时间线 -->
        <div
          style="min-width: 100%; height: 1300px; overflow-x: auto"
          :id="'actionChart' + 0"
        ></div>
        <!-- <Step :stepData="this.nodeList"></Step> -->
      </div>
    </el-dialog>

    <el-dialog title="查看流程" :visible.sync="dialogVisible" width="80%">
      <div class="container2"></div>
      <div class="container">
        <!-- <el-timeline>
          <el-timeline-item
            color="#000"
            v-for="(node, index) in nodeList2"
            :key="index"
          >
            <h2 align="left" style="margin-bottom: 10px">
              {{ node.name }}
            </h2>
            <steps :data="node.steps"></steps>
          </el-timeline-item>
        </el-timeline> -->
        <steps :data="startNodes"></steps>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
    <!-- 新增、编辑 -->
    <el-drawer
      :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%"
      style="height: 100%"
    >
      <add v-if="addDrawer" @close="handleClose" ref="newForm" :row="row"></add>
    </el-drawer>
  </div>
</template>

<script>
import Step from './step.vue'
import draggable from 'vuedraggable'
import * as echarts from 'echarts'
import {
  getPipelineTemplate,
  getsystemTypeEnum,
  deleteRole,
} from '@/api/public.js'
import add from './add.vue'
import steps from './steps.vue'
import _ from 'lodash'
export default {
  data() {
    return {
      searchInfo: {
        param: null,
      },
      tableData: [], // 表格数据
      showNode: false, // 是否显示节点流程图
      nodeList: [], // 当前查看的节点列表
      defaultProps: {
        children: 'nextActionTemplates',
        label: 'name',
      },
      systemTypeEnumOptions: [], // 系统选项
      addDrawer: false,
      row: null,
      dialogVisible: false,
      nodeList2: [],
      startNodes: {},
      num: 0,
    }
  },

  components: { add, draggable, steps, Step },

  computed: {},

  mounted() {
    this.init()
    this.getSystemList()
  },

  methods: {
    onEnd(event) {
      // 这里可以添加拖拽结束后的逻辑，例如保存新的顺序
      console.log('拖拽结束:', event, this.nodeList2)
    },
    // 搜索
    handleSearch(type) {
      if (type == 'clear') {
        this.searchInfo.param = null
      }
      this.init()
    },
    // 获取系统列表
    async getSystemList() {
      await getsystemTypeEnum({}).then(res => {
        if (res.success) {
          this.systemTypeEnumOptions = res.data
        } else {
          this.systemTypeEnumOptions = []
        }
      })
    },
    // 获取表格数据
    async init() {
      await getPipelineTemplate(this.searchInfo).then(res => {
        if (res.success) {
          this.tableData = res.data
        } else {
          this.tableData = []
        }
      })
    },
    // 查看流程
    checkNode(row) {
      if (row.nodes) {
        this.showNode = true
        this.nodeList = row.nodes
        console.log(this.nodeList, 'nodeList')
        this.$nextTick(() => {
          this.nodeList.forEach((element, index) => {
            let dom = 'dom' + index
            let chart = 'chart' + index
            console.log(element, index, dom, chart, 'giao')
            this.createOne(element, index, dom, chart)
          })
        })
      } else {
        this.$message({
          message: '该模板暂无流程图',
          type: 'error',
        })
      }
    },
    // 去掉大节点
    checkNode2(row) {
      if (row.actionTemplates) {
        this.showNode = true
        this.nodeList = row.actionTemplates
        console.log(this.nodeList, 'nodeList')
        // return
        this.$nextTick(() => {
          let index = 0
          let dom = 'dom' + index
          let chart = 'chart' + index
          this.createOne2(this.nodeList, index, dom, chart)
        })
      } else {
        this.$message({
          message: '该模板暂无流程图',
          type: 'error',
        })
      }
    },
    // 修改流程
    changeNode(row) {
      this.dialogVisible = true
      this.startNodes = handleNodes(row.actionTemplates)
      function handleNodes(node) {
        let res = []
        if (node.name) {
          res.push({
            name: node.name,
            origin: node,
            id: node.id,
            groupId: node.groupId,
            nextId: node.nextActionTemplates[0]?.id,
          })
          // 如果下一步节点数量大于1则和之前结果划分一层级
          if (node.nextActionTemplates.length > 1) {
            node.nextActionTemplates.forEach(data => {
              res = [...res, [...handleNodes(data)]]
            })
            // 如果下一步节点数量大于0则和之前结果平铺一层级
          } else if (node.nextActionTemplates.length > 0) {
            node.nextActionTemplates.forEach(data => {
              res = [...res, ...handleNodes(data)]
            })
          }
        }
        return res
      }
      this.startNodes = [
        this.startNodes.map(item => {
          if (Array.isArray(item)) {
            return this.handleGroups(item)
          } else {
            return item
          }
        }),
      ]
      console.log(this.startNodes, 'startNodes')
    },
    handleGroups(arr) {
      // 通过groupid提炼对应map，区分成散节点和块节点。 区分大节点数组和小节点块级数组样式渲染,尝试使用id排序
      // 添加节点和块下标进行顺序校正

      function handleArr2MapByGroupId(arr) {
        return arr.reduce((result, arrItem, index) => {
          arrItem.index = index
          let groupId = arrItem.origin.groupId ?? 'null'
          if (result.has(groupId)) {
            result.get(groupId).push(arrItem)
          } else {
            result.set(groupId, [arrItem])
          }
          return result
        }, new Map())
      }
      function handleMapToArr(map) {
        let arr = []
        for (const [key, value] of map) {
          if (key === 'null') {
            arr.push(...value)
          } else {
            arr.push(value)
          }
        }
        arr = arr.map(item => {
          if (Array.isArray(item)) {
            item.index = item[0].index
            item.block = true
          }
          return item
        })
        return arr
      }
      function handleArr2DoublyLinkedList(arr) {
        let map = new Map()
        arr.forEach((item, index) => {
          if (Array.isArray(item)) {
            map.set(item[0].id + '_' + index, item)
          } else {
            map.set(item.id + '_' + index, item)
          }
        })
        console.log(map, 'map')
        let res = []
        return res
      }

      function handleArrMoveAndAddTag(arr) {
        let bak = [...arr]
        let res = arr.map((item, index) => {
          if (Array.isArray(item)) {
            let targetId = item[0].id

            let targetIndex = bak.findIndex(item2 => {
              return item2.nextId === targetId
            })
            if (targetIndex !== -1) {
              item.delete = true
              let bakItem = _.cloneDeep(item)
              bakItem.index = item[0].index
              bak.splice(targetIndex + 1, 0, bakItem)
            } else {
              item.will = true
            }
          }
          return item
        })
        return [bak, res]
      }
      function filterArrByDeleteTag(arr) {
        return arr.filter(item => {
          return item.delete !== true
        })
      }
      function handleArrByTag(bak, arr) {
        return [bak, arr]
        bak = bak.map((item, index) => {
          item.index = index
          return item
        })
        let willArr = arr.filter(item => item.will)
        let notWillArr = bak.filter(item => {
          return Array.isArray(item) && !item.will
        })
        console.log(willArr, 'willArr')
        console.log(notWillArr, 'notWillArr')
        willArr.forEach(item => {
          let targetId = item[0].id
          let target = notWillArr.find(item2 => {
            return item2[item2.length - 1].nextId === targetId
          })
          if (target) {
            console.log(target, '找到了')
            item.delete = true
            let bakItem = _.cloneDeep(item)
            bak.splice(target.index + 1, 0, bakItem)
          } else {
            item.will = true
          }
        })
        return [bak, arr]
      }
      function handleArrSort(arr) {
        console.log('!!!')
        return arr.sort((a, b) => a.index - b.index)
      }
      let resultArr = handleMapToArr(handleArr2MapByGroupId(arr))
      // console.log(resultArr, 'map2arr')
      resultArr = handleArrSort(resultArr)
      // console.log(resultArr, 'sort')
      return resultArr
      // resultArr = handleArr2DoublyLinkedList(resultArr)
      // console.log(resultArr, 'arr2doublyLinkedList')
      let bak
        // 此时bak是位移且加了标签的
      ;[bak, resultArr] = handleArrMoveAndAddTag(resultArr)
      console.log(resultArr, 'resultArr 2')
      console.log(bak, 'bak2 ')
      while (resultArr.some(item => item.will)) {
        resultArr = filterArrByDeleteTag(bak)
        bak = _.cloneDeep(resultArr)
        ;[bak, resultArr] = handleArrByTag(bak, resultArr)
      }
      console.log(resultArr, 'resultArr 3')
      console.log(bak, 'bak3 ')
      resultArr = resultArr.map(item => {
        if (Array.isArray(item)) {
          item.block = true
        }
        return item
      })
      console.log(resultArr, 'resultArr 4')
      return resultArr
    },
    createAssemblyLine() {},
    handleAdd() {
      this.addDrawer = true
    },
    handleClose() {
      this.addDrawer = false
      this.init()
    },
    digui() {},
    // 创建节点动作流程树状图
    createOne(node, index, dom, chart) {
      dom = document.getElementById('actionChart' + index)
      chart = echarts.init(dom)
      this.dataTreating(node.actionTemplates)
      let data = node.actionTemplates
      console.log(data, 999)
      let option = {
        series: [
          {
            type: 'tree',
            // roam:true, 是否开启平移漫游

            expandAndCollapse: false, // 是否开启折叠功能
            id: 0,
            name: '流程图',
            data: [data],
            symbolSize: 8,
            edgeShape: 'polyline',
            edgeForkPosition: '63%',
            lineStyle: {
              width: 2,
            },
            label: {
              backgroundColor: '#fff',
              position: 'bottom',
              width: 70,
              overflow: 'break',
              verticalAlign: 'bottom',
              align: 'center',
              margin: [5, 5],
              borderWidth: 1, //文字添加边框
              borderColor: '#FFFFFF', //边框颜色
              backgroundColor: '#1890FF', //节点模块背景色
              borderRadius: 2, //圆角
              padding: [5, 4],
              textStyle: {
                color: '#FFFFFF', //文字颜色
                align: 'center', //文字的位置
              },
            },
            leaves: {
              label: {
                position: 'bottom',
                verticalAlign: 'bottom',
                align: 'center',
              },
            },
            emphasis: {
              focus: 'ancestor', // 聚焦所有祖先节点
            },
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      }
      option && chart.setOption(option)
    },
    // 去掉大节点
    createOne2(node, index, dom, chart) {
      dom = document.getElementById('actionChart' + index)
      chart = echarts.init(dom)
      console.log(node, index, dom, chart)
      this.dataTreating(node)
      let data = node
      console.log(data, 999)
      let option = {
        series: [
          {
            animation: false,
            type: 'tree',
            roam: true,
            // layout: 'orthogonal', // 正交布局
            // orient: 'vertical', // 垂直方向，从上到下

            expandAndCollapse: false, // 是否开启折叠功能
            id: 0,
            name: '流程图',
            data: [data],
            symbolSize: 8,
            edgeShape: 'polyline',
            edgeForkPosition: '63%',
            lineStyle: {
              width: 2,
            },
            label: {
              backgroundColor: '#fff',
              position: 'bottom',
              width: 70,
              overflow: 'break',
              verticalAlign: 'bottom',
              align: 'center',
              margin: [5, 5],
              borderWidth: 1, //文字添加边框
              borderColor: '#FFFFFF', //边框颜色
              backgroundColor: '#1890FF', //节点模块背景色
              borderRadius: 2, //圆角
              padding: [5, 4],
              textStyle: {
                color: '#FFFFFF', //文字颜色
                align: 'center', //文字的位置
              },
            },
            leaves: {
              label: {
                position: 'bottom',
                verticalAlign: 'bottom',
                align: 'center',
              },
            },
            emphasis: {
              focus: 'ancestor', // 聚焦所有祖先节点
            },
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      }
      console.log(option, 'option')
      // return
      option && chart.setOption(option)
    },
    // 递归处理数据
    // dataTreating(data) {
    //   if (this.num <= 2) {
    //     if (data.nextActionTemplates.length == 0) {
    //       return
    //     } else {
    //       data.children = data.nextActionTemplates
    //       data.children.forEach(element => {
    //         if (data.preActionResult == 'fail' || data.type == 'fail') {
    //           element.type = 'fail'
    //           data.type = 'fail'
    //           element.textStyle = { color: '#000' }
    //         }
    //         // if (element.preActionResult == 'fail' || element.type == 'fail') {
    //         //   element.id += 'fail'
    //         //   element.name += 'fail'
    //         // }
    //         this.dataTreating(element)
    //         this.num += 1
    //       })
    //     }
    //   }
    // },
    dataTreating(data) {
      // 检查 num 是否小于等于 2
      if (this.num > 10) {
        return
      }

      if (data.nextActionTemplates.length == 0) {
        return
      } else {
        data.children = data.nextActionTemplates
        data.children.forEach(element => {
          // 检查 num 是否小于等于 2

          if (data.preActionResult == 'fail' || data.type == 'fail') {
            element.type = 'fail'
            data.type = 'fail'
            element.textStyle = { color: '#000' }
          }
          this.num += 1
          this.dataTreating(element)
        })
      }
    },
  },
}
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s, transform 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.draggable-item {
  transition: all 1s;
}
.btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.container {
  height: 600px;
  overflow-y: auto;
  padding: 20px;
  /* padding-left: 20px; */
  font-size: 18px;
  /deep/ .el-tag {
  }
}

.eachNode {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .childNode {
    color: red;
    font-size: 16px;
  }
  .el-icon-bottom,
  .el-icon-right {
    font-size: 25px;
    margin: 5px;
  }
}
/* 流水节点 */
.el-timeline-item__content {
  span {
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
  }
}
/deep/.el-timeline-item__timestamp {
  color: #000 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
/deep/.el-step__description.is-wait,
/deep/.el-step__description.is-finish,
/deep/.el-step__description.is-error,
/deep/.el-step__description.is-success,
/deep/.el-step__description.is-process {
  margin-top: 5px;
}
</style>
