<template class="custom-tree-container">
    <section>
        <div class="block">
            <el-tree
                :data="data"
                :props="defaultProps"
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
                draggable
                :allow-drop="allowDrop"
                :allow-drag="allowDrag">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.name }}</span>
                <span>
                    <i class="el-icon-plus" @click="() => append(data)"></i>
                    <i class="el-icon-delete" @click="() => remove(node, data)"></i>
                </span>
                </span>
            </el-tree>
        </div>
    </section>
  </template>
  
  <script>
    let id = 1000;
    export default {
      data() {
        const data = [{
          id: 1,
          name: '一级 1',
          nextActionTemplates: [{
            id: 4,
            name: '二级 1-1',
            nextActionTemplates: [{
              id: 9,
              name: '三级 1-1-1'
            }, {
              id: 10,
              name: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          name: '一级 2',
          nextActionTemplates: [{
            id: 5,
            name: '二级 2-1'
          }, {
            id: 6,
            name: '二级 2-2'
          }]
        }, {
          id: 3,
          name: '一级 3',
          nextActionTemplates: [{
            id: 7,
            name: '二级 3-1'
          }, {
            id: 8,
            name: '二级 3-2'
          }]
        }];
        return {
          data: JSON.parse(JSON.stringify(data)),
          data: JSON.parse(JSON.stringify(data)),
          defaultProps: {
            children: 'nextActionTemplates',
            label: 'name'
          }
        }
      },
  
      methods: {
        append(data) {
          const newChild = { id: id++, name: 'testtest', nextActionTemplates: [] };
          if (!data.nextActionTemplates) {
            this.$set(data, 'nextActionTemplates', []);
          }
          data.nextActionTemplates.push(newChild);
        },
  
        remove(node, data) {
          const parent = node.parent;
          const nextActionTemplates = parent.data.nextActionTemplates || parent.data;
          const index = nextActionTemplates.findIndex(d => d.id === data.id);
          nextActionTemplates.splice(index, 1);
        },
  
        renderContent(h, { node, data, store }) {
          return (
            <span class="custom-tree-node">
              <span>{node.name}</span>
              <span>
                <el-button size="mini" type="text" on-click={ () => this.append(data) }>Append</el-button>
                <el-button size="mini" type="text" on-click={ () => this.remove(node, data) }>Delete</el-button>
              </span>
            </span>);
        },
        allowDrop(draggingNode, dropNode, type) {
            if (dropNode.data.name === '二级 3-1') {
              return type !== 'inner';
            } else {
              return true;
            }
        },
        allowDrag(draggingNode) {
            return draggingNode.data.name.indexOf('三级 3-2-2') === -1;
        }
      }
    };
  </script>
  
  <style>
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }
    .el-icon-delete{
        color: red;
        margin-left: 20px;
        font-size: 18px;
    }
    .el-icon-plus{
        color: green;
        font-size: 18px;
    }
  </style>